import { environment } from "environment";
import { TWarehouseMapAisleDataTableModel } from "wes_shell_app/server-models";
import { WarehouseMapWorkplaceSingleDataStoreProvider } from "wes_shell_app/warehouse-map-workplace-single-data-store-provider";

export class WarehouseMapRequestProvider extends WarehouseMapWorkplaceSingleDataStoreProvider<TWarehouseMapAisleDataTableModel> {
  protected serviceApiUrl = environment.serviceApi;
  _workplaceId: number;
  private static instance: WarehouseMapRequestProvider;

  public static getInstance(): WarehouseMapRequestProvider {
    if (!WarehouseMapRequestProvider.instance) {
      WarehouseMapRequestProvider.instance = new WarehouseMapRequestProvider();
    }
    return WarehouseMapRequestProvider.instance;
  }

  get url(): string {
    return `ui/map/aisle`;
  }

  setWorkplaceId(value: number) {
    this._workplaceId = value;
  }

  get endpointUrl(): string {
    return `ui/map/${this._workplaceId}/aisle`;
  }

  swapAisleUrl(aisleId: number): string {
    return `${this.url}/${aisleId}/swap`;
  }
}
