import { Box, FormLabel } from "@mui/material";
import Big from "wes_shell_app/big";
import LocalizedText from "wes_shell_app/localized-text";
import { IWithTranslation } from "wes_shell_app/types";

export type IKPIProps = IWithTranslation & {
  icon?: React.ReactNode;
  intlId?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export default function KPI({ intlId, children, icon, onClick }: IKPIProps) {
  return (
    <Box
      onClick={onClick}
      className={`flex flex-col justify-center ${
        onClick ? "cursor-pointer" : ""
      }`}
    >
      <FormLabel
        className="text-xs flex justify-center whitespace-nowrap"
        sx={{ fontSize: "0.75em" }}
      >
        <LocalizedText intlId={intlId} />
      </FormLabel>
      <div className="flex space-x-3 justify-center content-center">
        {icon}
        <Big sx={{ lineHeight: "2.5rem " }}>{children}</Big>
      </div>
    </Box>
  );
}
