import { Box } from "@mui/material";
import React, { useEffect } from "react";
import TextInputField from "wes_shell_app/text-input-field";
import { observer } from "mobx-react";
import { IModelDialogProps } from "wes_shell_app/model-dialog";
import { IAPIPrinterClientModel } from "models/client-models";
import { getTranslation } from "wes_shell_app/localization-utils";
import { usePrintersStore } from "../hooks/use-printers-store";
import { PrinterTypeSelector } from "./printer-type-selector";
import { PrinterModelSelector } from "./printer-model-selector";
import { PrinterProtocolSelector } from "./printer-protocol-selector";

export const PrinterAddForm = observer(
  (props: IModelDialogProps<IAPIPrinterClientModel>) => {
    const { item, patchItem } = props;

    const store = usePrintersStore();
    useEffect(() => {
      const stationId = store.stationId;
      patchItem({ stationId });
    }, []);

    return (
      <Box className="flex flex-col space-y-4 p-4">
        <Box className="flex justify-between space-x-4">
          <TextInputField
            label={getTranslation("printerCode")}
            value={item?.printerCode}
            onChange={(printerCode) => patchItem({ printerCode })}
            required={true}
          />
          <PrinterModelSelector
            value={item?.model}
            onChange={(model) => patchItem({ model })}
            required={true}
          />
        </Box>
        <Box className="flex justify-between space-x-4">
          <PrinterTypeSelector
            value={item?.type}
            onChange={(type) => patchItem({ type })}
            required={true}
          />
          <TextInputField
            label={getTranslation("name")}
            value={item?.name}
            onChange={(name) => patchItem({ name })}
            required={true}
          />
        </Box>
        <Box className="flex justify-between space-x-4">
          <TextInputField
            readOnly={item?.type === "portableBT"}
            label="ipAddress"
            value={item?.ipAddress}
            onChange={(ipAddress) => patchItem({ ipAddress })}
          />
          <TextInputField
            readOnly={item?.type === "network"}
            label="btName"
            value={item?.btName}
            onChange={(btName) => patchItem({ btName })}
          />
        </Box>
        <Box className="flex justify-between space-x-4">
          <PrinterProtocolSelector
            value={item?.protocol}
            onChange={(protocol) => patchItem({ protocol })}
            required={true}
          />
          <TextInputField
            label="description"
            value={item?.description}
            onChange={(description) => patchItem({ description })}
          />
        </Box>
      </Box>
    );
  }
);
