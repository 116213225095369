import Select from "wes_shell_app/select";
import { SelectItemType } from "wes_shell_app/types";
import { Close } from "@mui/icons-material";
import { isUndefined } from "lodash";
import Button from "wes_shell_app/button";
import { IAPIPrinterType } from "models/server-models";
import { getTranslation } from "wes_shell_app/localization-utils";

const items: SelectItemType[] = [
  {
    contentIntl: getTranslation("network"),
    content: "network",
    id: IAPIPrinterType.NETWORK,
  },
  {
    contentIntl: getTranslation("portableBT"),
    content: "portableBT",
    id: IAPIPrinterType.PORTABLEBT,
  },
];

type IPrinterTypeSelectorProps = {
  value: IAPIPrinterType;
  onChange: (v: IAPIPrinterType) => void;
  onClear?: () => void;
  required?: boolean;
};

export const PrinterTypeSelector = ({
  value,
  onChange,
  onClear,
  required,
}: IPrinterTypeSelectorProps) => {
  return (
    <Select
      fullWidth
      intlId="type"
      placeholder="type"
      items={items}
      required={required}
      value={!isUndefined(value) ? `${value}` : undefined}
      onChange={onChange}
      endAdornment={
        !isUndefined(value) && onClear ? (
          <div className="mr-4 flex align-center">
            <Button variant="small-icon" onClick={onClear}>
              <Close fontSize="small" color="action" />
            </Button>
          </div>
        ) : undefined
      }
    />
  );
};
