import { observer } from "mobx-react";
import React from "react";
import BaseView from "wes_shell_app/base-view";
import { TerminalDataTable } from "../components/terminal-data-table";
import { TerminalDataTableFilter } from "../components/terminal-data-table-filter";

const TerminalView = observer(() => {
  return (
    <BaseView>
      <TerminalDataTableFilter />
      <TerminalDataTable />
    </BaseView>
  );
});

export default TerminalView;
