import { Drawer } from "wes_shell_app/drawer";
import { useShelvesStore } from "../hooks/use-shelves-store";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import RackShelvesTable from "./rack-shelves-table";
import RackShelvesHeader from "./rack-shelves-header";
import RackVisualization from "./rack-visualization";
import Title from "wes_shell_app/title";
import { getTranslation } from "wes_shell_app/localization-utils";
import { useRackStore } from "../hooks/use-rack-store";
import RackLeaveDrawerDialog from "./dialogs/rack/rack-leave-drawer-dialog";
import { useState } from "react";

const RackShelvDrawer = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const shelvesStore = useShelvesStore();
  const rackStore = useRackStore();

  const handleCloseDrawer = () => {
    const checkDataSaved = shelvesStore.areArraysEqual(
      shelvesStore.rack.shelves,
      shelvesStore.shelves
    );

    if (!checkDataSaved) {
      setShowDialog(true);
    } else {
      shelvesStore.closeDrawer();
    }
  };

  const handleConfirmDrawer = () => {
    shelvesStore.closeDrawer();
    setShowDialog(false);
  };

  return (
    <>
      <RackLeaveDrawerDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        confirmAction={handleConfirmDrawer}
      />
      <Drawer
        anchor="right"
        onClose={handleCloseDrawer}
        open={shelvesStore.isDrawerOpen}
        sx={{ width: "70%" }}
      >
        <Title>{`${getTranslation("rack")}: ${shelvesStore.rack?.name}`}</Title>
        <Box
          display={"flex"}
          flexGrow={"initial"}
          width={"100%"}
          height={"100%"}
        >
          <RackVisualization />
          <Box
            width={"60%"}
            borderLeft={"1px solid #dfdfdf"}
            paddingLeft={"1rem"}
          >
            {(rackStore.isAdmin || rackStore.isLeader) && <RackShelvesHeader />}
            <Box className={"overflow-hidden mt-4"}>
              <RackShelvesTable />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
});

export default RackShelvDrawer;
