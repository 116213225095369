import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { useRackStore } from "../hooks/use-rack-store";
import { FilterSelector } from "common/common-components/selectors/filter-selector";
import { MobilityItems, ShelfTypeItems, StateItems } from "../utils/rack-utils";
import TextInputField from "wes_shell_app/text-input-field";

type IRackDataTableFilterProps = {
  actionContent?: React.ReactElement;
  cloneActionContent?: React.ReactElement;
};

const RackDataTableFilter = observer(
  ({ actionContent, cloneActionContent }: IRackDataTableFilterProps) => {
    const store = useRackStore();

    const showCloneButton =
      store.rightCloneShelv && store.showedRackCloneButton;

    return (
      <Box className="flex justify-between flex-wrap w-full gap-2 mt-2 mb-8">
        <Box className="flex flex-row gap-2 flex-wrap grow">
          <Box className="w-48">
            <FilterSelector
              intld={"state"}
              placeholder={"state"}
              value={store.getFilterValue("active")}
              items={StateItems}
              onChange={(v) => store.setFilter("active", v === "true")}
              onClear={() => store.clearFilter("active")}
            />
          </Box>
          <Box className="flex flex-row w-48">
            <TextInputField
              value={store.getFilterValue("name")}
              placeholder="name"
              label="name"
              onChange={(v) => store.setFilter("name", v)}
            />
          </Box>
          <Box className="w-48">
            <FilterSelector
              intld={"rackType"}
              placeholder={"rackType"}
              value={store.getFilterValue("type")}
              items={ShelfTypeItems}
              onChange={(v) => store.setFilter("type", v)}
              onClear={() => store.clearFilter("type")}
            />
          </Box>
          <Box className="w-48">
            <FilterSelector
              intld={"rackTypeMobile"}
              placeholder={"rackTypeMobile"}
              value={store.getFilterValue("mobility")}
              items={MobilityItems}
              onChange={(v) =>
                store.setFilter("mobility", v === "true" ? true : false)
              }
              onClear={() => store.clearFilter("mobility")}
            />
          </Box>
        </Box>
        <div className="flex gap-2 items-center ml-auto">
          {showCloneButton && (
            <div style={{ marginLeft: "auto" }}>{cloneActionContent}</div>
          )}
          {store.rightAddRack && (
            <div style={{ marginLeft: "auto" }}>{actionContent}</div>
          )}
        </div>
      </Box>
    );
  }
);

export default RackDataTableFilter;
