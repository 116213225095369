import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import { WarehouseMapRequestProvider } from "../stores/data/aisle/warehouse-map-request-provider";
import { useWarehouseMapAisleStore } from "../hooks/use-warehouse-map-aisle-store";
import { WareHouseMap } from "wes_shell_app/warehouse-map";
import { useWarehouseMapWorkPlaceStore } from "../hooks/use-warehouse-map-workplace-store";

const WarehouseMapView = observer(() => {
  const aisleStore = useWarehouseMapAisleStore();
  const workplaceStore = useWarehouseMapWorkPlaceStore();

  return (
    <BaseView>
      <WareHouseMap
        workplaceIsAdmin={workplaceStore.isAdmin}
        workplaceStore={workplaceStore}
        aisleStore={aisleStore}
        aisleIsAdmin={aisleStore.isAdmin}
        changePosition={aisleStore.changeRackPosition}
        rackProvider={aisleStore.getRackRequestProvider()}
        aisleProvider={WarehouseMapRequestProvider.getInstance()}
        setSelectAisle={aisleStore.setSelectAisleId}
      />
    </BaseView>
  );
});

export default WarehouseMapView;
