import { environment } from "environment";
import { TWarehouseMapAisleDataTableModel } from "wes_shell_app/server-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import WarehouseMapAisleDataTableStore from "wes_shell_app/warehouse-map-aisle-data-table-store";
import { TWarehouseMapAisleFilterParams } from "wes_shell_app/warehouse-map-utils";

export class WarehousMapDataTableProvider extends WarehouseMapAisleDataTableStore<
  TWarehouseMapAisleDataTableModel,
  TWarehouseMapAisleFilterParams
> {
  protected workplaceId: number;

  readonly sharedContextStore = new SharedContextStore();

  protected serviceApiUrl = environment.serviceApi;

  get url(): string {
    return `ui/map`;
  }

  setWorkplaceId(value: number) {
    this.workplaceId = value;
  }

  get endpointUrl(): string {
    return `${this.url}/${this.workplaceId}/aisle`;
  }
}
