import { IAPIClientTerminalModel } from "models/client-models";
import { IAPITerminalModel } from "models/server-models";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import {
  ITerminalFilterParams,
  TerminalDataTableProvider,
} from "../data/terminal-data-table-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { makeObservable } from "mobx";
import { serverToClientTerminalModel } from "models/mappers";

export class TerminalViewStore extends BaseTableViewStore<
  IAPITerminalModel,
  IAPIClientTerminalModel,
  ITerminalFilterParams,
  TerminalDataTableProvider
> {
  constructor() {
    super(new TerminalDataTableProvider());
    makeObservable(this);
  }

  mapServerToClientModel = serverToClientTerminalModel;

  logOut = async (item: IAPIClientTerminalModel) => {
    await this.tableProvider.logOut(item.terminalId, item.cardId);
  };

  private readonly appContext = new SharedContextStore();

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get stationId() {
    return this.currentUserStore.currentStationId;
  }
}
