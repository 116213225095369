import { environment } from "environment";
import { TControlNumberFilterParams } from "wes_shell_app/control-number-utils";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { TControlNumberRackPositions } from "wes_shell_app/server-models";
import ControlNumberPositionsDataTableStore from "wes_shell_app/control-number-position-data-table-store";

export class ControlNumberDataTableProvider extends ControlNumberPositionsDataTableStore<
  TControlNumberRackPositions,
  TControlNumberFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();

  protected serviceApiUrl = environment.serviceApi;

  protected workplaceId: number;

  get url(): string {
    return `ui/map`;
  }

  setWorkplaceId(value: number) {
    this.workplaceId = value;
  }

  get endpointUrl(): string {
    return `${this.url}/${this.workplaceId}/rack/position/controlNumbers`;
  }

  get defaultFilter() {
    return new Map<any, any>([["purpose", "picking"]]);
  }
}
