import { observer } from "mobx-react";
import React from "react";
import { IModelDialogProps } from "wes_shell_app/model-dialog";
import TextInputField from "wes_shell_app/text-input-field";
import { useRackStore } from "features/rack/hooks/use-rack-store";
import { Box } from "@mui/material";
import { IServerCreateRackModel } from "models/server-models";
import NumericInputField from "wes_shell_app/numeric-input-field";
import Select from "wes_shell_app/select";
import {
  MobilityItems,
  NumericRackingItems,
  NumericShelfItems,
  ShelfTypeItems,
  ZeroGroundItems,
} from "features/rack/utils/rack-utils";
import { getTranslation } from "wes_shell_app/localization-utils";

export const RackNewRackDialog = observer(
  (props: IModelDialogProps<IServerCreateRackModel>) => {
    const { item, patchItem } = props;
    const store = useRackStore();

    const rackStartHandler = (
      rackStartsWith: string,
      item: Partial<IServerCreateRackModel>
    ) => {
      const regex = store.selectedRackNumbering ? /^[0-9]*$/ : /^[A-Za-z]*$/;
      if (!regex.test(rackStartsWith)) {
        patchItem({ rackStartsWith: item.rackStartsWith });
        return;
      }
      patchItem({ rackStartsWith });
    };

    const shelfStartHandler = (
      shelfStartsWith: string,
      item: Partial<IServerCreateRackModel>
    ) => {
      const regex = store.selectedRackShelfNumbering
        ? /^[0-9]*$/
        : /^[A-Za-z]*$/;
      if (!regex.test(shelfStartsWith)) {
        patchItem({ shelfStartsWith: item.shelfStartsWith });
        return;
      }
      patchItem({ shelfStartsWith });
    };

    return (
      <Box className="flex flex-col justify-between space-y-4">
        <TextInputField
          required
          value={item?.name}
          label="name"
          onChange={(name) => patchItem({ name })}
        />
        <TextInputField
          value={item?.description}
          label="description"
          onChange={(description) => patchItem({ description })}
        />
        <Box className="flex flex-row gap-2">
          <NumericInputField
            required
            value={item?.length}
            label={`${getTranslation("rackLength")} (mm)`}
            onChange={(length) => patchItem({ length })}
            min={0}
          />
          <NumericInputField
            required
            value={item?.depth}
            label={`${getTranslation("rackDepth")} (mm)`}
            onChange={(depth) => patchItem({ depth })}
            min={0}
          />
          <Select
            required
            fullWidth
            label={getTranslation("type")}
            value={item?.type}
            items={ShelfTypeItems}
            onChange={(type) => patchItem({ type: String(type) })}
          />
        </Box>
        <Box className="flex flex-row gap-2">
          <Select
            required
            fullWidth
            label={getTranslation("rackTypeMobile")}
            value={
              String(item?.mobility) === "undefined"
                ? undefined
                : String(item?.mobility)
            }
            items={MobilityItems}
            onChange={(mobility) =>
              patchItem({ mobility: mobility === "true" ? true : false })
            }
          />
          <Select
            required
            fullWidth
            label={getTranslation("rackStorageUnder")}
            value={
              String(item?.zeroGround) === "undefined"
                ? undefined
                : String(item?.zeroGround)
            }
            items={ZeroGroundItems}
            onChange={(zeroGround) =>
              patchItem({
                zeroGround: zeroGround === "true" ? true : false,
              })
            }
          />
        </Box>
        <Box className="flex flex-row gap-2">
          <Select
            required
            fullWidth
            label={getTranslation("rackNumerickMarking")}
            value={
              String(item?.numberingRack) === "undefined"
                ? undefined
                : String(item?.numberingRack)
            }
            items={NumericRackingItems}
            onChange={(numberingRack) => {
              patchItem({
                numberingRack: numberingRack === "true" ? true : false,
              });
              patchItem({ rackStartsWith: "" });
              store.setRackNumberingUuid(true);
              store.setRackNumbering(numberingRack === "true");
            }}
          />
          <TextInputField
            required
            value={item?.rackStartsWith}
            label="rackMarkingShelvesFrom"
            onChange={(rackStartsWith) =>
              rackStartHandler(rackStartsWith, item)
            }
            readOnly={!store.selectedRackNumberingUuid}
          />
        </Box>
        <Box className="flex flex-row gap-2">
          <Select
            required
            fullWidth
            label={getTranslation("rackNumerickPlaces")}
            value={
              String(item?.numberingShelf) === "undefined"
                ? undefined
                : String(item?.numberingShelf)
            }
            items={NumericShelfItems}
            onChange={(numberingShelf) => {
              patchItem({
                numberingShelf: numberingShelf === "true" ? true : false,
              });
              patchItem({ shelfStartsWith: "" });
              store.setRackShelfNumberingUuid(true);
              store.setRackShelfNumbering(numberingShelf === "true");
            }}
          />
          <TextInputField
            required
            value={item?.shelfStartsWith}
            label="rackMarkingPlacesFrom"
            onChange={(shelfStartsWith) =>
              shelfStartHandler(shelfStartsWith, item)
            }
            readOnly={!store.selectedRackShelfNumberingUuid}
          />
        </Box>
      </Box>
    );
  }
);
