import { IDataTableHeaderCellProps } from "wes_shell_app/data-table";

export const columns: IDataTableHeaderCellProps[] = [
  { label: "lot", sortKey: "lot" },
  { label: "created", sortKey: "issued" },
  { label: "batchId", sortKey: "batchId" },

  { label: "zone", sortKey: "zone" },
  { label: "extZone", sortKey: "extZone", info: "extZoneInfo" },
  { label: "status", sortKey: "status" },
  { label: "priority", sortKey: "priority" },
  { label: "supportCount", sortKey: "supportCount", info: "supportCountInfo" },
  { label: "supportCode", sortKey: "supportCode", info: "supportCodeInfo" },
  { label: "customer", sortKey: "customer" },
  {
    label: "expDockAddress",
    sortKey: "expDockAddress",
    info: "expDockAddressInfo",
  },
  { label: "checkDigit", sortKey: "checkDigit", info: "checkDigitInfo" },
  { label: "lotType", sortKey: "lotType", info: "lotTypeInfo" },
  { label: "qtySum", sortKey: "qtySum" },
  {
    label: "lotOrder",
    sortKey: "lotOrder",
    info: "lotOrderInfo",
  },
  { label: "itemsCount", sortKey: "itemsCount", info: "itemsCountInfo" },
  { label: "preparedTo", sortKey: "preparedTo" },
  { label: "lotStartDate", sortKey: "lotStartDate", info: "lotStartDateInfo" },
  { label: "lotEndDate", sortKey: "lotEndDate", info: "lotEndDateInfo" },
  { label: "", sticky: true },
];
