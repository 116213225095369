import { environment } from "environment";
import ControlNumberRequestProvider from "wes_shell_app/control-number-request-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class ControlNumberReqProvider extends ControlNumberRequestProvider {
  protected serviceApiUrl = environment.serviceApi;
  readonly sharedContextStore = new SharedContextStore();

  protected workplaceId: number;

  setWorkplaceId(value: number) {
    this.workplaceId = value;
  }

  get url(): string {
    return `ui/map/rack/position/`;
  }

  get generateEndpointUrl(): string {
    return `${this.url}controlNumbers/`;
  }

  getSaveOneRecordEndpointUrl = (id: number): string => {
    return `ui/map/${this.workplaceId}/rack/position/${id}/controlNumbers`;
  };

  saveAllRecordsEndpointUrl = (): string => {
    return `ui/map/${this.workplaceId}/rack/position/controlNumbers`;
  };

  generateControlNumbersEndpointUrl = (): string => {
    return `ui/map/${this.workplaceId}/rack/position/controlNumbers/generate`;
  };

  csvExportEndpointUrl = (): string => {
    return `ui/map/${this.workplaceId}/rack/position/controlNumbers/export`;
  };
}
