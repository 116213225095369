import { observer } from "mobx-react";
import { useItemsViewStore } from "../hooks/use-items-store";
import { Box } from "@mui/material";
import DateTimePicker from "wes_shell_app/date-time-picker";
import NumericInputField from "wes_shell_app/numeric-input-field";
import Select from "wes_shell_app/select";
import TextInputField from "wes_shell_app/text-input-field";
import MultiSelect from "wes_shell_app/multi-select";
import {
  IDynamicItemsFilterParams,
  DynamicItemsFilterParams,
} from "../utils/filter-utils";
import Button from "wes_shell_app/button";
import { Close } from "@mui/icons-material";
import { isUndefined } from "lodash";
import { getTranslation } from "wes_shell_app/localization-utils";

export const ItemsDataTableFilter = observer(() => {
  const store = useItemsViewStore();
  const width = "200px";

  return (
    <>
      <Box className="flex flex-row justify-between w-full mt-2 mb-6">
        <Box className="flex flex-wrap gap-3 ">
          <Box sx={{ width: { width } }}>
            <MultiSelect
              fullWidth={true}
              maxItemsToShow={2}
              label="Filters"
              value={store.filtersSelection.map((filter) => filter.name)}
              onChange={(v) => {
                const selectedFilters = v.map((name) =>
                  DynamicItemsFilterParams.find(
                    (filter) => filter.name === name
                  )
                );
                store.setFiltersSelection(selectedFilters);
              }}
              items={DynamicItemsFilterParams.map((filter) => {
                return {
                  contentIntl: filter.name,
                  id: filter.name,
                };
              })}
            />
          </Box>

          {store.filtersSelection.map((filter) => {
            switch (filter.type) {
              case "string":
                return (
                  <Box key={filter.name} sx={{ width: { width } }}>
                    <TextInputField
                      label={filter.name}
                      value={store.getFilterValue(filter.name)}
                      onChange={(v) => {
                        store.setFilter(filter.name, v);
                      }}
                    />
                  </Box>
                );
              case "select":
                const value = store.getFilterValue(filter.name);
                return (
                  <Box key={filter.name} sx={{ width: { width } }}>
                    <Select
                      fullWidth
                      intlId={filter.name}
                      placeholder={filter.name}
                      items={filter.selectOptions}
                      value={!isUndefined(value) ? `${value}` : undefined}
                      onChange={(v) => store.setFilter(filter.name, v)}
                      endAdornment={
                        !isUndefined(value) ? (
                          <div className="mr-4 flex align-center">
                            <Button
                              variant="small-icon"
                              onClick={() => store.clearFilter(filter.name)}
                            >
                              <Close fontSize="small" color="action" />
                            </Button>
                          </div>
                        ) : undefined
                      }
                    />
                  </Box>
                );
              case "date":
                return (
                  <Box key={filter.name} sx={{ width: { width } }}>
                    <DateTimePicker
                      label={getTranslation(filter.name)}
                      value={store.getFilterValue(filter.name)}
                      onConfirm={(v) =>
                        store.setDateFilterValue(filter.name, v)
                      }
                    />
                  </Box>
                );
              case "number":
                return (
                  <Box key={filter.name} sx={{ width: { width } }}>
                    <NumericInputField
                      label={filter.name}
                      value={store.getFilterValue(filter.name)}
                      onChange={(v) => {
                        store.setFilter(filter.name, v);
                      }}
                    />
                  </Box>
                );
            }
          })}
        </Box>
      </Box>
    </>
  );
});
