import { Box } from "@mui/material";
import React from "react";
import TextInputField from "wes_shell_app/text-input-field";
import { observer } from "mobx-react";
import { IModelDialogProps } from "wes_shell_app/model-dialog";
import { IAPIPrinterClientModel } from "models/client-models";
import { getTranslation } from "wes_shell_app/localization-utils";
import { usePrintersStore } from "../hooks/use-printers-store";
import { PrinterTypeSelector } from "./printer-type-selector";
import { PrinterModelSelector } from "./printer-model-selector";
import { PrinterProtocolSelector } from "./printer-protocol-selector";

export const PrinterEditForm = observer(
  (props: IModelDialogProps<IAPIPrinterClientModel>) => {
    const store = usePrintersStore();

    return (
      <Box className="flex flex-col space-y-4 p-4">
        <Box className="flex justify-between space-x-4">
          <TextInputField
            label={getTranslation("printerCode")}
            value={store.selectedPrinter?.printerCode || ""}
            onChange={(printerCode) => store.setEditData({ printerCode })}
            required={true}
          />
          <PrinterModelSelector
            value={store.selectedPrinter?.model}
            onChange={(model) => store.setEditData({ model })}
            required={true}
          />
        </Box>
        <Box className="flex justify-between space-x-4">
          <PrinterTypeSelector
            value={store.selectedPrinter?.type}
            onChange={(type) => store.setEditData({ type })}
            required={true}
          />
          <TextInputField
            label={getTranslation("name")}
            value={store.selectedPrinter?.name}
            onChange={(name) => store.setEditData({ name })}
            required={true}
          />
        </Box>
        <Box className="flex justify-between space-x-4">
          <TextInputField
            readOnly={store.selectedPrinter?.type === "portableBT"}
            label="ipAddress"
            value={store.selectedPrinter?.ipAddress}
            onChange={(ipAddress) => store.setEditData({ ipAddress })}
          />
          <TextInputField
            readOnly={store.selectedPrinter?.type === "network"}
            label="btName"
            value={store.selectedPrinter?.btName}
            onChange={(btName) => store.setEditData({ btName })}
          />
        </Box>
        <Box className="flex justify-between space-x-4">
          <PrinterProtocolSelector
            value={store.selectedPrinter?.protocol}
            onChange={(protocol) => store.setEditData({ protocol })}
            required={true}
          />
          <TextInputField
            label="description"
            value={store.selectedPrinter?.description}
            onChange={(description) => store.setEditData({ description })}
          />
        </Box>
      </Box>
    );
  }
);
