import { makeAutoObservable } from "mobx";
import { parse } from "cookie";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { environment } from "environment";
import {
  IWebsocketMessageModel,
  IWebsocketTableMessagesModel,
  IWebsocketTableReadyOrdersMessagesModel,
  IWebsocketTableWorkersWithTaskMessagesModel,
  WebsocketMessageType,
} from "models/server-models";
import {
  IWebsocketTableMessagesClientModelContent,
  IWebsocketTableReadyOrdersClientModelContent,
  IWebsocketTableWorkersWithTaskClientModelContent,
} from "models/client-models";
import {
  serverToClientHeaderInfoModel,
  serverToClientTableMessageContent,
  serverToClientTableReadyOrdersModel,
} from "models/mappers";
import { DashboardDataProvider } from "./data/dashboard-data-provider";

const TOKEN = "wes-token";

interface IAuthTokens {
  accessToken: string;
}

export const getToken = (): IAuthTokens => ({
  accessToken: parse(document.cookie)?.[TOKEN],
});

export class DashboardViewStore {
  private readonly sharedContext: SharedContextStore = new SharedContextStore();

  constructor() {
    makeAutoObservable(this);
  }

  processMessagesDataTable: IWebsocketTableMessagesClientModelContent[] = [];
  technologyMessagesDataTable: IWebsocketTableMessagesClientModelContent[] = [];
  workersWithTaskMessagesDataTable: IWebsocketTableWorkersWithTaskClientModelContent[] =
    [];
  readyOrdersMessagesDataTable: IWebsocketTableReadyOrdersClientModelContent[] =
    [];
  headerInfoId: number = 0;
  ordersToday: [number, number] = [0, 0];
  ordersTotalToday: number = 0;
  picksToday: [number, number] = [0, 0];
  picksTotalToday: number = 0;
  currentWorkers: number = 0;
  ordersCompletedToday: number = 0;
  picksCompletedToday: number = 0;
  tableProvider = new DashboardDataProvider();

  setHeaderInfoId = (v: number) => {
    this.headerInfoId = v;
  };

  setOrdersTotalToday = (v: number) => {
    this.ordersTotalToday = v;
  };

  setPicksTotalToday = (v: number) => {
    this.picksTotalToday = v;
  };

  setCurrentWorkers = (v: number) => {
    this.currentWorkers = v;
  };

  setOrdersCompletedToday = (v: number) => {
    this.ordersCompletedToday = v;
  };

  setPicksCompletedToday = (v: number) => {
    this.picksCompletedToday = v;
  };

  setPicksToday = (v: [number, number]) => {
    this.picksToday = v;
  };

  setOrdersToday = (v: [number, number]) => {
    this.ordersToday = v;
  };

  setProcessMessagesTableData = (v: IWebsocketTableMessagesModel) => {
    this.processMessagesDataTable = v.content.map((x) =>
      serverToClientTableMessageContent(
        x,
        this.getModule(x.appmoduleUuid)?.name
      )
    );
  };

  setTechnologyMessagesTableData = (v: IWebsocketTableMessagesModel) => {
    this.technologyMessagesDataTable = v.content.map((x) =>
      serverToClientTableMessageContent(
        x,
        this.getModule(x.appmoduleUuid)?.name
      )
    );
  };

  setWorkersWithTaskMessagesTableData = (
    v: IWebsocketTableWorkersWithTaskMessagesModel
  ) => {
    this.workersWithTaskMessagesDataTable = v.content;
  };

  setReadyOrdersMessagesTableData = (
    v: IWebsocketTableReadyOrdersMessagesModel
  ) => {
    this.readyOrdersMessagesDataTable = v.content.map((x) =>
      serverToClientTableReadyOrdersModel(x)
    );
  };

  logOut = async (item: IWebsocketTableWorkersWithTaskClientModelContent) => {
    await this.tableProvider.logOut(item.terminalId, item.cardId);
  };

  get currentUserStore() {
    return this.sharedContext.appContext.accessRights;
  }

  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }

  //websockets
  wsConnection: WebSocket;
  timeout;

  get isLoaded() {
    return this.sharedContext.appContext.isContextLoaded;
  }
  private getModule = (uuid: string) =>
    this.sharedContext.appContext.getModule(uuid);

  private getClientIpAddress = async () => {
    try {
      const response = await fetch("https://ipapi.co/json");
      const result = await response.json();
      return result.ip;
    } catch (e) {}
  };

  openConnection = async () => {
    console.log("openConnection");
    const clientIpAddress = await this.getClientIpAddress();
    this.wsConnection = new WebSocket(
      `${environment.dashboardWs}${this.sharedContext.appContext.currentStationId}`
    );
    const authMessage = {
      message: "login",
      client: clientIpAddress,
      jwt: getToken().accessToken,
    };

    if (this.wsConnection.readyState === 1) {
      this.wsConnection.send(JSON.stringify(authMessage));
    }

    this.wsConnection.onopen = () => {
      console.log("this.wsConnection.onopen");
      this.wsConnection.send(JSON.stringify(authMessage));
    };

    this.wsConnection.onerror = async (event) => {
      console.log("error", event);
    };

    this.wsConnection.onmessage = async (event) => {
      console.log("this.wsConnection.onmessage");
      // first check if connection is live
      if (this.wsConnection.readyState === 1) {
        const data: IWebsocketMessageModel = JSON.parse(event.data);
        if (data.messageType === WebsocketMessageType.process) {
          this.setProcessMessagesTableData(data.message);
        } else if (data.messageType === WebsocketMessageType.technical) {
          this.setTechnologyMessagesTableData(data.message);
        } else if (data.messageType === WebsocketMessageType.headerInfo) {
          const {
            id,
            currentWorkers,
            ordersTotalToday,
            picksTotalToday,
            ordersCompletedToday,
            picksCompletedToday,
            ordersToday,
            picksToday,
          } = serverToClientHeaderInfoModel(data.message);
          this.setHeaderInfoId(id);
          this.setOrdersToday(ordersToday);
          this.setOrdersTotalToday(ordersTotalToday);
          this.setPicksToday(picksToday);
          this.setPicksTotalToday(picksTotalToday);
          this.setCurrentWorkers(currentWorkers);
          this.setOrdersCompletedToday(ordersCompletedToday);
          this.setPicksCompletedToday(picksCompletedToday);
        } else if (data.messageType === WebsocketMessageType.workersWithTask) {
          this.setWorkersWithTaskMessagesTableData(data.message);
        } else if (data.messageType === WebsocketMessageType.readyOrders) {
          this.setReadyOrdersMessagesTableData(data.message);
        }
      }
    };
  };

  closeConnection = () => {
    if (this.wsConnection) {
      this.wsConnection.close();
    }
  };
}
