import { SelectItemType } from "wes_shell_app/types";

export type IOrdersFilterParams =
  | "status"
  | "batchId"
  | "lot"
  | "customer"
  | "zone"
  | "extZone"
  | "priority"
  | "expDockAddress"
  | "lotOrder"
  | "itemsCount"
  | "issuedFrom"
  | "issuedTo"
  | "preparedFrom"
  | "preparedTo"
  | "lotStartDateTo"
  | "lotStartDateFrom"
  | "lotEndDateFrom"
  | "lotEndDateTo";

export type IItemsFilterParams =
  | "pickAddress"
  | "aisle"
  | "bay"
  | "level"
  | "cell"
  | "status"
  | "itemId"
  | "itemName";

export type IDynamicOrdersFilterParams = {
  name: IOrdersFilterParams;
  type: "string" | "select" | "date" | "number";
  selectOptions?: SelectItemType[];
};

export type IDynamicItemsFilterParams = {
  name: IItemsFilterParams;
  type: "string" | "select" | "date" | "number";
  selectOptions?: SelectItemType[];
};

export const Status: SelectItemType[] = [
  {
    contentIntl: "NEW",
    id: "NEW",
  },
  {
    contentIntl: "STARTED",
    id: "STARTED",
  },
  {
    contentIntl: "INPICKING",
    id: "INPICKING",
  },
  {
    contentIntl: "ONHOLD",
    id: "ONHOLD",
  },
  {
    contentIntl: "FINISHED",
    id: "FINISHED",
  },
];

export const ItemsStatus: SelectItemType[] = [
  {
    contentIntl: "NEW",
    id: "NEW",
  },
  {
    contentIntl: "PARTPICKED",
    id: "PARTPICKED",
  },
  {
    contentIntl: "PICKED",
    id: "PICKED",
  },
];

export const DynamicOrdersFilterParams: IDynamicOrdersFilterParams[] = [
  {
    name: "status",
    type: "select",
    selectOptions: Status,
  },
  {
    name: "batchId",
    type: "number",
  },
  {
    name: "lot",
    type: "number",
  },
  {
    name: "customer",
    type: "string",
  },
  {
    name: "issuedFrom",
    type: "date",
  },
  {
    name: "issuedTo",
    type: "date",
  },
  {
    name: "zone",
    type: "number",
  },
  {
    name: "extZone",
    type: "string",
  },
  {
    name: "priority",
    type: "number",
  },
  {
    name: "expDockAddress",
    type: "string",
  },
  {
    name: "lotOrder",
    type: "number",
  },
  {
    name: "itemsCount",
    type: "number",
  },
  {
    name: "preparedFrom",
    type: "date",
  },
  {
    name: "preparedTo",
    type: "date",
  },
  {
    name: "lotStartDateTo",
    type: "date",
  },
  {
    name: "lotStartDateFrom",
    type: "date",
  },
  {
    name: "lotEndDateTo",
    type: "date",
  },
  {
    name: "lotEndDateFrom",
    type: "date",
  },
];
export const DynamicItemsFilterParams: IDynamicItemsFilterParams[] = [
  { name: "pickAddress", type: "string" },
  { name: "aisle", type: "string" },
  { name: "bay", type: "string" },
  { name: "level", type: "string" },
  { name: "cell", type: "string" },
  { name: "status", type: "select", selectOptions: ItemsStatus },
  { name: "itemId", type: "string" },
  { name: "itemName", type: "string" },
];
