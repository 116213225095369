import { environment } from "environment";
import { IAPITerminalModel } from "models/server-models";
import alerts from "wes_shell_app/alerts";
import { updateItem } from "wes_shell_app/api-utils";
import DataTableStore from "wes_shell_app/data-table-store";
import { getTranslation } from "wes_shell_app/localization-utils";
import SharedContextStore from "wes_shell_app/shared-context-store";
export type ITerminalFilterParams =
  | "registeredFrom"
  | "registeredTo"
  | "unregisteredFrom"
  | "unregisteredTo"
  | "firstname"
  | "surname"
  | "cardId"
  | "terminalId"
  | "isLoggedIn";

export class TerminalDataTableProvider extends DataTableStore<
  IAPITerminalModel,
  ITerminalFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();
  get endpointUrl(): string {
    return `ui/${this.stationId}/terminal`;
  }

  protected serviceApiUrl = environment.serviceApi;
  get stationId() {
    return this.sharedContextStore.appContext.currentStationId;
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setHours(0, 0, 0, 0);
    return timeFrom;
  }

  get defaultFilter() {
    return new Map<ITerminalFilterParams, any>([
      ["isLoggedIn", true],
      ["registeredFrom", this.defaultTimeFromValue.toISOString()],
    ]);
  }

  get filterDependencies(): Map<
    ITerminalFilterParams,
    ITerminalFilterParams[]
  > {
    return new Map<ITerminalFilterParams, ITerminalFilterParams[]>();
  }

  logOut = async (terminalId: string, cardId: number) => {
    try {
      await updateItem(
        `${this.serviceApiUrl}${this.endpointUrl}/${terminalId}?cardId=${cardId}`
      );
      alerts.success({ message: getTranslation("terminalLogoutSuccess") });
      this.forceReload();
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      console.log(errorModel.message);
      alerts.error({ message: getTranslation("terminalLogoutError") });
    }
  };
}
