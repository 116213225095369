import { makeObservable } from "mobx";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { TWarehouseMapWorkplaceFilterParams } from "wes_shell_app/warehouse-map-utils";
import { WarehousMapDataWorkplaceTableProvider } from "./data/workplace/warehouse-map-workplace-data-table-provider";
import {
  TWarehouseMapRackModel,
  TWarehouseMapWorplaceDataTableModel,
} from "wes_shell_app/server-models";
import { TWarehouseMapWorkplaceClientModel } from "wes_shell_app/client-models";
import { serverToClientWarehouseMapWorkplaceModel } from "wes_shell_app/mappers";
import WarehouseMapWorkplacebaseTableViewStore from "wes_shell_app/warehouse-map-workplace-base-table-view-store";
import { WarehousMapRackDataProvider } from "./data/workplace/warehouse-map-rack-data-provider";

export class WarehouseMapWorkplaceStore extends WarehouseMapWorkplacebaseTableViewStore<
  TWarehouseMapWorplaceDataTableModel,
  TWarehouseMapWorkplaceClientModel,
  TWarehouseMapRackModel,
  TWarehouseMapWorkplaceFilterParams,
  WarehousMapDataWorkplaceTableProvider,
  WarehousMapRackDataProvider
> {
  constructor() {
    super(
      new WarehousMapDataWorkplaceTableProvider(),
      new WarehousMapRackDataProvider()
    );
    makeObservable(this, {});
  }

  mapServerToClientModel = (item): TWarehouseMapWorkplaceClientModel =>
    serverToClientWarehouseMapWorkplaceModel(item);

  private readonly appContext = new SharedContextStore();

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return (
      this.currentUserStore.isLoaded &&
      this.tableProvider.isLoaded &&
      this.racksProvider.isLoaded
    );
  }
}
