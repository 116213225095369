import { makeObservable } from "mobx";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { WarehousMapDataTableProvider } from "./data/aisle/warehouse-map-data-table-provider";
import { TWarehouseMapAisleFilterParams } from "wes_shell_app/warehouse-map-utils";
import { WarehouseMapRackRequestProvider } from "./data/aisle/warehouse-map-rack-request-provider";
import { WarehouseMapRequestProvider } from "./data/aisle/warehouse-map-request-provider";
import { WarehouseMapRowRequestProvider } from "./data/aisle/warehouse-map-row-request-provider";
import { TWarehouseMapAisleDataTableModel } from "wes_shell_app/server-models";
import WarehouseMapAislebaseTableViewStore from "wes_shell_app/warehouse-map-aisle-base-table-view-store";

export class WarehouseMapAisleStore extends WarehouseMapAislebaseTableViewStore<
  TWarehouseMapAisleDataTableModel,
  TWarehouseMapAisleFilterParams,
  WarehousMapDataTableProvider
> {
  constructor() {
    super(
      new WarehousMapDataTableProvider(),
      WarehouseMapRequestProvider.getInstance(),
      new WarehouseMapRackRequestProvider(),
      new WarehouseMapRowRequestProvider()
    );
    makeObservable(this, {});
  }

  private readonly appContext = new SharedContextStore();

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }

  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }

  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
}
