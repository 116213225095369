import { environment } from "environment";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { IOrdersModel } from "models/server-models";
import { OrdersViewStore } from "features/orders/stores/orders-view-store";
import { IOrdersFilterParams } from "../utils/filter-utils";

export class OrdersDataTableProvider extends DataTableStore<
  IOrdersModel,
  IOrdersFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();
  get endpointUrl(): string {
    return `ui/${this.stationId}/lot`;
  }

  protected serviceApiUrl = environment.serviceApi;

  get stationId() {
    return this.sharedContextStore.appContext.currentStationId;
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setHours(timeFrom.getHours() - 1);

    return timeFrom;
  }

  get defaultFilter() {
    return new Map<IOrdersFilterParams, any>([
      ["issuedFrom", this.defaultTimeFromValue.toISOString()],
    ]);
  }

  get filterDependencies(): Map<IOrdersFilterParams, IOrdersFilterParams[]> {
    return new Map<IOrdersFilterParams, IOrdersFilterParams[]>();
  }
}
