import { Dialog } from "wes_shell_app/dialog";
import Text from "wes_shell_app/text";

interface RackLeaveDrawerDialogProps {
  open: boolean;
  onClose: () => void;
  confirmAction: () => void;
}

const RackLeaveDrawerDialog = (props: RackLeaveDrawerDialogProps) => {
  const { open, onClose, confirmAction } = props;

  return (
    <Dialog
      intlTitle="warning"
      open={open}
      cancelAction={onClose}
      confirmAction={confirmAction}
    >
      <Text intlId="leaveDrawer" />
    </Dialog>
  );
};

export default RackLeaveDrawerDialog;
