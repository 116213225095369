import Select from "wes_shell_app/select";
import { SelectItemType } from "wes_shell_app/types";
import { Close } from "@mui/icons-material";
import { isUndefined } from "lodash";
import Button from "wes_shell_app/button";
import { usePrintersStore } from "../hooks/use-printers-store";
import { useEffect, useState } from "react";

type IPrinterTypeSelectorProps = {
  value: string;
  onChange: (v: string) => void;
  onClear?: () => void;
  required?: boolean;
};

export const PrinterModelSelector = ({
  value,
  onChange,
  onClear,
  required,
}: IPrinterTypeSelectorProps) => {
  const [items, setItems] = useState<SelectItemType[] | null>(null);
  const store = usePrintersStore();

  useEffect(() => {
    if (store.printerModels) {
      const transformedItems: SelectItemType[] = store.printerModels.map(
        (model) => ({
          content: model,
          id: model,
        })
      );
      setItems(transformedItems);
    }
  });

  return (
    <Select
      fullWidth
      intlId="model"
      placeholder="model"
      items={items}
      value={!isUndefined(value) ? `${value}` : undefined}
      onChange={onChange}
      required={required}
      endAdornment={
        !isUndefined(value) && onClear ? (
          <div className="mr-4 flex align-center">
            <Button variant="small-icon" onClick={onClear}>
              <Close fontSize="small" color="action" />
            </Button>
          </div>
        ) : undefined
      }
    />
  );
};
