import { observer } from "mobx-react";
import { useTerminalStore } from "../hooks/use-terminal-store";
import { Box } from "@mui/material";
import TextInputField from "wes_shell_app/text-input-field";
import DateTimePicker from "wes_shell_app/date-time-picker";
import NumericInputField from "wes_shell_app/numeric-input-field";
import Text from "wes_shell_app/text";
import { Checkbox } from "wes_shell_app/checkbox";

export const TerminalDataTableFilter = observer(() => {
  const store = useTerminalStore();

  return (
    <>
      <Box className="flex flex-col w-full space-y-2 mt-2">
        <Box className="flex flex-row justify-between space-x-2 w-full">
          <TextInputField
            label="firstname"
            value={store.getFilterValue("firstname")}
            onChange={(v) => store.setFilter("firstname", v)}
          />
          <TextInputField
            label="surname"
            value={store.getFilterValue("surname")}
            onChange={(v) => store.setFilter("surname", v)}
          />
          <DateTimePicker
            fullWidth
            intlId="registeredFrom"
            value={store.getFilterValue("registeredFrom")}
            onConfirm={(v) => store.setDateFilterValue("registeredFrom", v)}
            defaultValue={store.defaultTimeFromValue}
          />
          <DateTimePicker
            fullWidth
            intlId="registeredTo"
            value={store.getFilterValue("registeredTo")}
            onConfirm={(v) => store.setDateFilterValue("registeredTo", v)}
          />
        </Box>
        <Box className="flex flex-row justify-between space-x-2 w-full">
          <NumericInputField
            label="cardId"
            value={store.getFilterValue("cardId")}
            onChange={(v) => store.setFilter("cardId", v)}
          />
          <TextInputField
            label="terminalId"
            value={store.getFilterValue("terminalId")}
            onChange={(v) => store.setFilter("terminalId", v)}
          />
          <DateTimePicker
            fullWidth
            intlId="unregisteredFrom"
            value={store.getFilterValue("unregisteredFrom")}
            onConfirm={(v) => store.setDateFilterValue("unregisteredFrom", v)}
            defaultValue={store.defaultTimeFromValue}
          />
          <DateTimePicker
            fullWidth
            intlId="unregisteredTo"
            value={store.getFilterValue("unregisteredTo")}
            onConfirm={(v) => store.setDateFilterValue("unregisteredTo", v)}
          />
        </Box>
        <Box className="ml-2 mb-2">
          <Checkbox
            intlId="isLoggedIn"
            value={store.getFilterValue("isLoggedIn")}
            onChange={(v) => store.setFilter("isLoggedIn", v)}
          />
        </Box>
      </Box>
    </>
  );
});
