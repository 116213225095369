import { Box, Table, TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import DataTable from "wes_shell_app/data-table";
import { useOrdersViewStore } from "../hooks/use-orders-store";
import { IClientOrdersModel } from "models/client-models";
import { OrdersViewStore } from "../stores/orders-view-store";
import { useItemsViewStore } from "../hooks/use-items-store";
import { ItemsViewStore } from "../stores/items-view-store";
import { columns } from "../utils/table-orders-columns";
import Button from "wes_shell_app/button";
import { List } from "@mui/icons-material";
import formatters from "wes_shell_app/localization-utils";
import NumericInputField from "wes_shell_app/numeric-input-field";
import { useState } from "react";

export const OrdersDataTable = observer(() => {
  const store = useOrdersViewStore();
  const itemsStore = useItemsViewStore();
  const handleSelectOrderId = (item: IClientOrdersModel) => {
    itemsStore.selectItem = item;
    itemsStore.setSelectedOrderId(item.id);
    itemsStore.openDrawer();
  };

  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      onPageChange={store.setPage}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      pageCount={store.totalCount}
      rows={store.items}
      pageSize={store.pageSize}
      onPageSizeChange={store.setPageSize}
      columns={columns}
      rowTemplate={(item) => (
        <RowTemplate
          key={item.id}
          item={item}
          store={store}
          itemsStore={itemsStore}
          onSelect={() => handleSelectOrderId(item)}
        />
      )}
    />
  );
});

type IRowTemplateProps = {
  item: IClientOrdersModel;
  store: OrdersViewStore;
  itemsStore: ItemsViewStore;
  onSelect: () => void;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { onSelect, item, store } = props;
  const [oldPriority, setOldPriority] = useState<number>(item.priority);

  const priorityUpdate = async () => {
    if (oldPriority !== item.priority) {
      await store.priorityUpdateAPI(item.id, item.priority);
      setOldPriority(item.priority);
      store.forceReload();
    }
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.lot}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {formatters.formatDateTime(item.issued as any)}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.batchId}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {`(${item?.workplaceId}) ${item?.workplaceName}`}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.zoneExternal}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.status}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <NumericInputField
              value={item?.priority}
              onChange={(v: number) => (item.priority = v)}
              onConfirm={() => priorityUpdate()}
              size="small"
            />
          </Box>
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {item.supportCount}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.supportCode}</TableCell>
        <TableCell
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {item.customer}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {item.expDockAddress}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.checkDigit}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.lotType}</TableCell>
        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {item.qtySum}
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {item.lotOrder}
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {item.itemsCount}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {formatters.formatDateTime(item.preparedTo as any)}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {formatters.formatDateTime(item.lotStartDate as any)}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {formatters.formatDateTime(item.lotEndDate as any)}
        </TableCell>
        <TableCell
          style={{
            position: "sticky",
            right: 0,
            background: "white",
            zIndex: 1,
          }}
        >
          <Button
            onClick={onSelect}
            variant="icon"
            icon={<List color="primary" />}
          />
        </TableCell>
      </TableRow>
    </>
  );
});
