import { runInAction } from "mobx";
import { DashboardViewStore } from "features/dashboard/stores/dashboard-view-store";
import { PrintersViewStore } from "features/printers/stores/view/printers-view-store";
import { ParametersViewStore } from "wes_shell_app/parameters-view-store";
import { ParametersDataTableProvider } from "features/parameters/stores/parameters-data-table-provider";
import { RackViewStore } from "features/rack/stores/rack-view-store";
import { RackShelvesStore } from "features/rack/stores/rack-shelves-store";
import { TerminalViewStore } from "features/terminal/stores/view/terminal-view-store";
import { OrdersViewStore } from "features/orders/stores/orders-view-store";
import { ItemsViewStore } from "features/orders/stores/items-view-store";
import { ControlNumberViewStore } from "features/warehouse-map-control-number/stores/control-number-view-store";
import { WarehouseMapAisleStore } from "features/warehouse-map/stores/warehouse-map-aisle-store";
import { WarehouseMapWorkplaceStore } from "features/warehouse-map/stores/warehouse-map-workplace-store";
import { ControlNumberWorkplaceStore } from "features/warehouse-map-control-number/stores/control-number-workplace-store";

export class ViewStore {
  readonly dashboard = new DashboardViewStore();
  readonly printers = new PrintersViewStore();
  readonly parameters = new ParametersViewStore(
    new ParametersDataTableProvider()
  );
  readonly racks = new RackViewStore();
  readonly terminals = new TerminalViewStore();
  readonly orders = new OrdersViewStore();
  readonly items = new ItemsViewStore();
  readonly warehouseMapAisle = new WarehouseMapAisleStore();
  readonly warehouseMapWorkPlace = new WarehouseMapWorkplaceStore();
  readonly controlNumber = new ControlNumberViewStore();
  readonly controlNumberWorkPlace = new ControlNumberWorkplaceStore();
}

export class CommonStore {
  readonly shelvesStore = new RackShelvesStore();
}

export class RootStore {
  readonly view = new ViewStore();
  readonly common = new CommonStore();
}

export let rootStore = new RootStore();

export function initStores() {
  rootStore = runInAction(() => new RootStore());
}

export default rootStore;
