import { IClientItemsModel, IClientOrdersModel } from "models/client-models";
import { IItemsModel } from "models/server-models";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { makeObservable, observable, action } from "mobx";
import { ItemsDataTableProvider } from "./items-data-table-provider";
import { IDynamicItemsFilterParams, IItemsFilterParams } from "../utils/filter-utils";
import { getTranslation } from "wes_shell_app/localization-utils";
import alerts from "wes_shell_app/alerts";
import { updateItem } from "wes_shell_app/api-utils";

export class ItemsViewStore extends BaseTableViewStore<
  IItemsModel,
  IClientItemsModel,
  IItemsFilterParams,
  ItemsDataTableProvider
> {
  constructor() {
    super(new ItemsDataTableProvider());
    makeObservable(this, {
      selectedOrderId: observable,
      setSelectedOrderId: action,
      drawer: observable,
      openDrawer: action,
      closeDrawer: action,
      filtersSelection: observable,
      setFiltersSelection: action,
    });
  }
  drawer: boolean = false;
  selectedOrderId: number | null = null;
  selectedItem: IClientOrdersModel | null = null;
  filtersSelection: IDynamicItemsFilterParams[] = [];

  setFiltersSelection = (filters: IDynamicItemsFilterParams[]) => {
    this.filtersSelection = filters;
  }

  setSelectedOrderId(id: number) {
    this.selectedOrderId = id;
    this.tableProvider.setOrderId(id);
    this.tableProvider.forceReload();
  }

  set selectItem(item: IClientOrdersModel | null) {
    this.selectedItem = item;
  }


  openDrawer = () => {
    this.drawer = true;
  };
  closeDrawer = () => {
    this.drawer = false;
  };
  private readonly appContext = new SharedContextStore();

  get isDrawerOpen() {
    return this.drawer;
  }

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get stationId() {
    return this.currentUserStore.currentStationId;
  }
}
