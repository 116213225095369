import NumericInputField from "wes_shell_app/numeric-input-field";
import { useShelvesStore } from "features/rack/hooks/use-shelves-store";
import { Box } from "@mui/material";
import { getTranslation } from "wes_shell_app/localization-utils";
import Select from "wes_shell_app/select";
import { SelectItemType } from "wes_shell_app/types";
import { observer } from "mobx-react";

const RackShelfDialogForm = observer(
  ({ editLevel }: { editLevel: boolean }) => {
    const store = useShelvesStore();

    const purposeItems: SelectItemType[] = store.shelfPurposes.map(
      (item: string) => ({
        contentIntl: item,
        id: item,
      })
    );

    return (
      <Box className={"flex flex-col gap-4"}>
        <Box className={"flex gap-4"}>
          <NumericInputField
            required
            value={store.dialogShelf?.height}
            label={`${getTranslation("shelvHeight")} (mm)`}
            onChange={(height) => {
              store.setDialogShelf({ height });
            }}
            min={0}
          />
          <NumericInputField
            required
            value={store.dialogShelf?.positions}
            label="shelvNumberPosition"
            onChange={(positions) => {
              store.setDialogShelf({ positions });
            }}
            min={0}
          />
        </Box>
        <Box className={"flex gap-4"}>
          <Select
            fullWidth
            required
            label={getTranslation("shelvPurpose")}
            items={purposeItems}
            value={store.dialogShelf?.purpose}
            onChange={(purpose) => {
              store.setDialogShelf({ purpose: String(purpose) });
            }}
          />
          {editLevel && (
            <NumericInputField
              value={store.dialogShelf?.level}
              label="shelvPlacementLevel"
              onChange={(level) => {
                store.setDialogShelf({ level });
              }}
              min={0}
            />
          )}
        </Box>
      </Box>
    );
  }
);

export default RackShelfDialogForm;
