import { environment } from "environment";
//TODO use real model
import { IWebSocketHeaderInfoModel } from "models/server-models";
import alerts from "wes_shell_app/alerts";
import { updateItem } from "wes_shell_app/api-utils";
import { getTranslation } from "wes_shell_app/localization-utils";
import SharedContextStore from "wes_shell_app/shared-context-store";
import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";

export class DashboardDataProvider extends SingleDataStoreProvider<IWebSocketHeaderInfoModel> {
  get endpointUrl(): string {
    return "ui";
  }
  readonly sharedContextStore = new SharedContextStore();

  get patchEndpoint(): string {
    return `${this.serviceApiUrl}${this.endpointUrl}`;
  }
  protected serviceApiUrl = environment.serviceApi;

  get stationId() {
    return this.sharedContextStore.appContext.currentStationId;
  }

  logOut = async (terminalId: string, cardId: number) => {
    try {
      await updateItem(
        `${this.serviceApiUrl}ui/${this.stationId}/terminal/${terminalId}?cardId=${cardId}`
      );
      alerts.success({ message: getTranslation("terminalLogoutSuccess") });
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      console.log(errorModel.message);
      alerts.error({ message: getTranslation("terminalLogoutError") });
    }
  };
}
