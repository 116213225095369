import { IClientOrdersModel } from "models/client-models";
import { IOrdersModel } from "models/server-models";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { makeObservable, observable, action } from "mobx";
import { environment } from "environment";
import { OrdersDataTableProvider } from "features/orders/stores/orders-data-table-provider";
import {
  IDynamicOrdersFilterParams,
  IOrdersFilterParams,
} from "../utils/filter-utils";
import { updateItem } from "wes_shell_app/api-utils";
import { getTranslation } from "wes_shell_app/localization-utils";
import alerts from "wes_shell_app/alerts";

export class OrdersViewStore extends BaseTableViewStore<
  IOrdersModel,
  IClientOrdersModel,
  IOrdersFilterParams,
  OrdersDataTableProvider
> {
  constructor() {
    super(new OrdersDataTableProvider());
    makeObservable(this, {
      filtersSelection: observable,
      setFiltersSelection: action,
    });
  }

  filtersSelection: IDynamicOrdersFilterParams[] = [
    {
      name: "issuedFrom",
      type: "date",
    },
  ];
  setFiltersSelection = (filters: IDynamicOrdersFilterParams[]) => {
    this.filtersSelection = filters;
  };

  async priorityUpdateAPI(id: number, priority: number) {
    try {
      await updateItem(
        `${environment.serviceApi}ui/${this.stationId}/lot/${id}?priority=${priority}`,
        {}
      );
      alerts.success({ message: getTranslation("priorityChange") });
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  }
  private readonly appContext = new SharedContextStore();

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get stationId() {
    return this.currentUserStore.currentStationId;
  }
}
