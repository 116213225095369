import { environment } from "environment";
import { IAPIPrinterModel } from "models/server-models";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { deleteItem } from "wes_shell_app/api-utils";
import { IAPIPrinterClientModel } from "models/client-models";

export type IPrintersFilterParams = "type" | "name" | "stationId";

export class PrintersDataTableProvider extends DataTableStore<
  IAPIPrinterModel,
  IPrintersFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();
  get endpointUrl(): string {
    return `ui/${this.stationId}/printer`;
  }

  protected serviceApiUrl = environment.serviceApi;
  get stationId() {
    return this.sharedContextStore.appContext.currentStationId;
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setDate(timeFrom.getDate() - 1);
    return timeFrom;
  }

  get defaultFilter() {
    return new Map<IPrintersFilterParams, any>([]);
  }

  get filterDependencies(): Map<
    IPrintersFilterParams,
    IPrintersFilterParams[]
  > {
    return new Map<IPrintersFilterParams, IPrintersFilterParams[]>();
  }
  async deletePrinter(printer: IAPIPrinterClientModel) {
    try {
      await deleteItem(
        environment.serviceApi + `ui/${this.stationId}/printer`,
        printer.id
      );

      this.forceReload();
    } catch (error) {
      console.error("Failed to delete printer", error);
    }
  }
}
