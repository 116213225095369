/**
 * Mapps data comming from server API to client models
 */

import * as serverModels from "./server-models";
import * as clientModels from "./client-models";
import formatters from "wes_shell_app/localization-utils";

export const serverToClientHeaderInfoModel = (
  item: serverModels.IWebSocketHeaderInfoModel
): clientModels.IClientHeaderInfoModel => ({
  ...item,
  ordersToday: [item.ordersCompletedToday, item.ordersTotalToday],
  picksToday: [item.picksCompletedToday, item.picksTotalToday],
});

export const serverToClientRackModel = (
  item: serverModels.IServerRackModel
): clientModels.IClientRackModel => ({
  ...item,
  shelfType: item.mobility ? "mobile" : "static",
  shelves: item.shelves.map((shelf, index) => ({
    ...shelf,
    shelfId: index + 1,
  })),
});

export const serverToClientTerminalModel = (
  item
): clientModels.IAPIClientTerminalModel => {
  return {
    ...item,
    registered: formatters.formatDateTime(item.registered),
    unregistered: formatters.formatDateTime(item.unregistered),
  };
};

export const serverToClientTableMessageContent = (
  item: serverModels.IWebsocketTableMessagesServerModelContent,
  clientModule: string
): clientModels.IWebsocketTableMessagesClientModelContent => ({
  ...item,
  appmoduleName: clientModule || "--",
});

export const serverToClientTableReadyOrdersModel = (
  item: serverModels.IWebsocketTableReadyOrdersServerModelContent
): clientModels.IWebsocketTableReadyOrdersClientModelContent => {
  return {
    ...item,
    issued: formatters.formatDateTime(item.issued),
    preparedTo: formatters.formatDateTime(item.preparedTo),
  };
};
