import { Drawer } from "wes_shell_app/drawer";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import { useItemsViewStore } from "../hooks/use-items-store";
import { ItemsDataTable } from "./items-data-table";
import { useEffect } from "react";
import { ItemsDataTableFilter } from "./items-data-table-filters";
import Title from "wes_shell_app/title";
import { getTranslation } from "wes_shell_app/localization-utils";
import { useOrdersViewStore } from "../hooks/use-orders-store";
import Small from "wes_shell_app/small";
import formatters from "wes_shell_app/localization-utils";

export const ItemsDrawer = observer(() => {
  const store = useItemsViewStore();
  const ordersStore = useOrdersViewStore();

  return (
    <Drawer
      anchor="right"
      onClose={store.closeDrawer}
      open={store.isDrawerOpen}
      sx={{ width: "80%" }}
    >
      <Title sx={{ marginBottom: "10px" }}>{`${getTranslation(
        "orderDetailInfo"
      )}: ${store.selectedItem?.lot}`}</Title>
      <Small>{`${getTranslation("orderDate")}: ${formatters.formatDateTime(
        store.selectedItem?.issued as any
      )}`}</Small>
      <ItemsDataTableFilter />
      <ItemsDataTable />
    </Drawer>
  );
});
