import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";
import { environment } from "environment";
import { makeObservable, observable } from "mobx";
import { TWarehouseMapRackModel } from "wes_shell_app/server-models";

export class WarehouseMapRackRequestProvider extends SingleDataStoreProvider<TWarehouseMapRackModel> {
  protected serviceApiUrl = environment.serviceApi;

  constructor() {
    super();
    makeObservable(this, {
      aisleId: observable,
    });
  }

  aisleId: number = null;

  setAisleId(value: number) {
    this.aisleId = value;
  }

  get url(): string {
    return `ui/map/rack`;
  }

  get endpointUrl(): string {
    return `ui/map/aisle/${this.aisleId}/rack`;
  }
}
