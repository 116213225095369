import { observer } from "mobx-react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { IWebsocketTableReadyOrdersClientModelContent } from "models/client-models";
import { Box, TableCell, TableRow } from "@mui/material";
import { useDashboardStore } from "../hooks/use-dashboard-store";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "order",
  },
];

const DashboardOrdersDataTable = observer(() => {
  const store = useDashboardStore();

  if (!store.isLoaded) return null;

  return (
    <>
      <DataTable
        rows={store.readyOrdersMessagesDataTable}
        hidePaging
        columns={columns}
        rowTemplate={(item) => <RowTemplate key={item.id} item={item} />}
      />
    </>
  );
});

export default DashboardOrdersDataTable;

type IRowTemplateProps = {
  item: IWebsocketTableReadyOrdersClientModelContent;
};

const RowTemplate = (props: IRowTemplateProps) => {
  const { item } = props;
  const { orderNumber, issued, priority, preparedTo } = item;

  return (
    <TableRow>
      <TableCell>
        <Box>{orderNumber}</Box>
        <Box>{priority}</Box>
        <Box>{`${issued} - ${preparedTo}`}</Box>
      </TableCell>
    </TableRow>
  );
};
