import { observer } from "mobx-react";
import { useDashboardStore } from "../hooks/use-dashboard-store";
import { Box } from "@mui/material";
import { SimpleCard } from "wes_shell_app/simpleCard";
import KpiCharts from "common/base-components/kpi/kpi-charts";
import SimplePieChart from "wes_shell_app/pie-chart";
import { getTranslation } from "wes_shell_app/localization-utils";
import KPI from "common/base-components/kpi/kpi";
import { Person } from "@mui/icons-material";

const DashboardHeader = observer(() => {
  const store = useDashboardStore();
  const { currentWorkers, ordersToday, picksToday } = store;
  return (
    <>
      <Box className="flex w-full gap-2 flex-wrap">
        <SimpleCard topMargin={false} className="flex-1">
          <KpiCharts
            chart={
              <SimplePieChart
                currentCount={ordersToday[0]}
                maxCount={ordersToday[1]}
                labels={[getTranslation("completed"), getTranslation("total")]}
                colors={["000", "rgb(219 219 219)"]}
                tooltip={store.ordersToday[1] === 0 ? false : true}
              />
            }
            intlId="todaysOrdersCount"
            value={store.ordersToday}
            description={[getTranslation("completed"), getTranslation("total")]}
          ></KpiCharts>
        </SimpleCard>
        <SimpleCard topMargin={false} className="flex-1">
          <KpiCharts
            chart={
              <SimplePieChart
                currentCount={picksToday[0]}
                maxCount={picksToday[1]}
                labels={[getTranslation("picked"), getTranslation("remaining")]}
                colors={["000", "rgb(219 219 219)"]}
                tooltip={store.picksToday[1] === 0 ? false : true}
              />
            }
            intlId="todayPickedOrders"
            value={store.picksToday}
            description={[getTranslation("picked"), getTranslation("total")]}
          ></KpiCharts>
        </SimpleCard>
        <SimpleCard
          topMargin={false}
          className="flex-1 max-w-[12rem] items-center"
        >
          <KPI
            icon={
              <Person
                fontSize="large"
                color={currentWorkers > 0 ? "primary" : "inherit"}
              />
            }
            intlId="currentActiveWorkers"
          >
            {currentWorkers}
          </KPI>
        </SimpleCard>
      </Box>
    </>
  );
});

export default DashboardHeader;
