import { Box } from "@mui/material";
import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import { SimpleCard } from "wes_shell_app/simpleCard";
import Title from "wes_shell_app/title";
import DashboardProcessDataTable from "../components/dashboard-process-data-table";
import DashboardTechnologyDataTable from "../components/dashboard-technology-data-table";
import DashboardHeader from "../components/dashboard-header";
import DashboardWorkersDataTable from "../components/dashboard-workers-data-table";
import DashboardOrdersDataTable from "../components/dashboard-orders-data-table";
import { useDashboardStore } from "../hooks/use-dashboard-store";
import { useEffect } from "react";

const DashboardView = observer(() => {
  const store = useDashboardStore();

  useEffect(() => {
    store.openConnection();

    return () => {
      store.closeConnection();
    };
  }, [store]);

  return (
    <BaseView cardBackgroundColor={false}>
      <DashboardHeader />
      <Box className="flex">
        <Box className="w-full max-w-[85%]">
          <SimpleCard>
            <Title intlId="processTableMessagesLabel" />
            <DashboardProcessDataTable />
          </SimpleCard>
          <SimpleCard>
            <Title intlId="technologyTableLabel" />
            <DashboardTechnologyDataTable />
          </SimpleCard>
        </Box>
        <Box className="pl-2 w-full max-w-[25%]">
          <SimpleCard>
            <Title intlId="workersWithAssignedTasks" />
            <DashboardWorkersDataTable />
          </SimpleCard>
          <SimpleCard>
            <Title intlId="listOfReadyOrders" />
            <DashboardOrdersDataTable />
          </SimpleCard>
        </Box>
      </Box>
    </BaseView>
  );
});

export default DashboardView;
