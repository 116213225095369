import { observer } from "mobx-react";
import useConfirmDialog from "wes_shell_app/use-confirm-dialog";
import ConfirmDialog from "wes_shell_app/dialog-confirm-button";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { IWebsocketTableWorkersWithTaskClientModelContent } from "models/client-models";
import { Box, TableCell, TableRow } from "@mui/material";
import { useDashboardStore } from "../hooks/use-dashboard-store";
import Button from "wes_shell_app/button";
import { Logout } from "@mui/icons-material";
import { DashboardViewStore } from "../stores/dashboard-view-store";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "worker",
  },
  {
    label: "",
  },
];

const DashboardWorkersDataTable = observer(() => {
  const store = useDashboardStore();
  const confirmDialog = useConfirmDialog();

  if (!store.isLoaded) return null;

  return (
    <>
      <DataTable
        rows={store.workersWithTaskMessagesDataTable}
        hidePaging
        columns={columns}
        rowTemplate={(item) => (
          <RowTemplate
            key={item.id}
            item={item}
            store={store}
            confirmDialog={confirmDialog}
          />
        )}
      />
      <ConfirmDialog
        onConfirm={async () => {
          store.logOut(confirmDialog.item);
        }}
      />
    </>
  );
});

export default DashboardWorkersDataTable;

type IRowTemplateProps = {
  item: IWebsocketTableWorkersWithTaskClientModelContent;
  store: DashboardViewStore;
  confirmDialog: {
    open: (item: IWebsocketTableWorkersWithTaskClientModelContent) => void;
  };
};

const RowTemplate = (props: IRowTemplateProps) => {
  const { store, item, confirmDialog } = props;
  const { firstName, lastName, fulfilment, login, terminalId, orderNumber } =
    item;

  return (
    <TableRow>
      <TableCell>
        <Box>{`${firstName} ${lastName}`}</Box>
        <Box>{orderNumber}</Box>
        <Box>{fulfilment && `${fulfilment}%`}</Box>
        <Box>{terminalId}</Box>
      </TableCell>
      <TableCell sx={{ width: "10%" }}>
        {store.isAdmin && login && (
          <Button
            variant="icon"
            icon={<Logout color="warning" />}
            onClick={() => confirmDialog.open(item)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
