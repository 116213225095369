import { makeAutoObservable, observable } from "mobx";
import { IClientRackModel } from "models/client-models";
import {
  IServerCreateRackShelfModel,
  IServerShelfPurposeModel,
} from "models/server-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { RackShelvesNameStore } from "./rack-shelves-name-store";
import { dataGET, updateItem } from "wes_shell_app/api-utils";
import { environment } from "environment";
import alerts from "wes_shell_app/alerts";
import { RackShelfPurposeProvider } from "./data/rack-shelf-purpose-provider";
const _ = require("lodash");

export class RackShelvesStore {
  constructor() {
    makeAutoObservable(this, {
      shelves: observable,
    });
  }
  private readonly appContext = new SharedContextStore();
  private readonly rackShelveNameStore = new RackShelvesNameStore();

  drawer: boolean = false;
  rack: IClientRackModel | null = null;
  shelves: IServerCreateRackShelfModel[] = [];
  shelvTableRowSelected: number | null = null;
  showedShelvCloneButton: boolean = false;
  selectedCloneShelf: IServerCreateRackShelfModel | null = null;
  dialogShelf: Partial<IServerCreateRackShelfModel> = {};
  shelfId: number = 1;
  rackShelfPurposeProvider = new RackShelfPurposeProvider();
  shelfPurposes: string[] = [];

  private _newShelvName = (): string => {
    return this.shelves.length > 0
      ? this.rackShelveNameStore.getNextPosition(this.shelves[0]?.name)
      : this.rack.rackStartsWith;
  };

  private _newShelvLevel = (): number => {
    return this.shelves ? this.shelves.length + 1 : 1;
  };

  private _sortShelvesByLevelDesc = () => {
    this.shelves = this.shelves.sort((a, b) => b.level - a.level);
  };

  private _renameShelves = () => {
    this.shelves = this.shelves.sort((a, b) => a.level - b.level);
    this.shelves.forEach((shelf, index) => {
      shelf.name =
        index === 0
          ? this.rack.rackStartsWith
          : this.rackShelveNameStore.getNextPosition(
              this.shelves[index - 1].name
            );
    });
    this._sortShelvesByLevelDesc();
  };

  private _newLevelForAllShelves = (isEditShelv: boolean) => {
    const lastLevelInShelv =
      this.shelves.length > 0
        ? Math.max(...this.shelves.map((item) => item.level))
        : 0;

    if (this.shelves.length === 0) {
      this.dialogShelf.level = 1;
    } else if (this.dialogShelf.level <= lastLevelInShelv) {
      this.shelves = this.shelves.map((shelf) => {
        if (shelf.level >= this.dialogShelf.level) {
          return {
            ...shelf,
            level: shelf.level + 1,
          };
        }
        return shelf;
      });
    } else if (this.dialogShelf.level === lastLevelInShelv) {
      this.shelves = this.shelves.map((shelf) => {
        const newLevel = shelf.level > 1 ? shelf.level - 1 : 1;
        return {
          ...shelf,
          level: newLevel,
        };
      });
    } else {
      if (!isEditShelv) {
        this.dialogShelf.level = lastLevelInShelv + 1;
      }
    }
  };

  private _newShelvesLevel = () => {
    let level = 1;
    this.shelves = this.shelves.sort((a, b) => a.level - b.level);
    this.shelves = this.shelves.map((shelf) => {
      return {
        ...shelf,
        level: level++,
      };
    });
    this._sortShelvesByLevelDesc();
  };

  private _getShelfId = () => {
    return ++this.shelfId;
  };

  private _resetShelfId = () => {
    this.shelfId = 1;
  };

  private _setShelfId = (id: number) => {
    this.shelfId = id;
  };

  async getShelfPurposes() {
    try {
      const response = await dataGET<IServerShelfPurposeModel>(
        `${environment.serviceApi}${this.rackShelfPurposeProvider.endpointUrl}`
      );
      this.shelfPurposes = response.content;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error(errorModel.message);
    }
  }

  areArraysEqual = (
    oldShelves: IServerCreateRackShelfModel[],
    newShelves: IServerCreateRackShelfModel[]
  ): boolean => {
    if (oldShelves.length !== newShelves.length) return false;

    return oldShelves.every((item, index) =>
      _.isEqual(item, newShelves[index])
    );
  };

  closeDrawer = () => {
    this.drawer = false;
    this.resetRackData();
    this.resetSelectedCloneShelf();
    this.resetShelvTableRowSelected();
    this._resetShelfId();
  };

  openDrawer = () => {
    this.drawer = true;
    this._setShelfId(
      this.shelves.length > 0
        ? Math.max(...this.shelves.map((item) => item.shelfId))
        : this.shelfId
    );
  };

  deleteShelf = (id: number) => {
    const index = this.shelves.findIndex((item) => item.shelfId === id);
    if (index !== -1) {
      this.shelves.splice(index, 1);
      this._renameShelves();
      this._newShelvesLevel();
      this.shelves = [...this.shelves];
      this.resetSelectedCloneShelf();
      this.resetShelvTableRowSelected();
    }
  };

  addNewShelf = () => {
    this.dialogShelf.name = this._newShelvName();
    this.dialogShelf.level = this.dialogShelf.level || this._newShelvLevel();
    this.dialogShelf.shelfId = this._getShelfId();
    this._newLevelForAllShelves(false);
    this.shelves.push(this.dialogShelf as IServerCreateRackShelfModel);
    this.shelves = [...this.shelves];
    this._renameShelves();
    this.resetDialogShelf();
  };

  editShelf = () => {
    const index = this.shelves.findIndex(
      (item) => item.shelfId === this.dialogShelf.shelfId
    );
    if (index !== -1) {
      this.shelves[index] = {
        ...this.shelves[index],
        ...this.dialogShelf,
      };

      this.shelves = [...this.shelves];
      this.resetDialogShelf();
    }
  };

  setDialogShelf = (shelf: Partial<IServerCreateRackShelfModel>) => {
    this.dialogShelf = {
      ...this.dialogShelf,
      ...shelf,
    };
  };

  resetDialogShelf = () => {
    this.dialogShelf = {};
  };

  resetShelfPurpose = () => {
    this.dialogShelf.purpose = "";
  };

  setShelvTableRowSelected = (id: number) => {
    this.shelvTableRowSelected = id;
  };

  resetShelvTableRowSelected = () => {
    this.shelvTableRowSelected = null;
  };

  setShelvPlanCloneButton(value: boolean) {
    this.showedShelvCloneButton = value;
  }

  resetSelectedCloneShelf() {
    this.selectedCloneShelf = null;
  }

  setSelectedCloneShelf(id: number) {
    this.selectedCloneShelf =
      this.shelves.find((item) => item.shelfId === id) || null;
  }

  resetRackData = () => {
    this.rack = null;
    this.shelves = [];
  };

  shelvLevelUp(level: number) {
    const index = this.shelves.findIndex((item) => item.level === level);
    const maxLevel = this.shelves.length;

    if (index !== -1 && level < maxLevel) {
      const nextIndex = this.shelves.findIndex(
        (item) => item.level === level + 1
      );
      if (nextIndex !== -1) {
        this.shelves[index].level += 1;
        this.shelves[nextIndex].level -= 1;
      }
    }
    this.shelves = [...this.shelves];
    this._renameShelves();
  }

  shelvLevelDown(level: number) {
    const index = this.shelves.findIndex((item) => item.level === level);

    if (index !== -1 && level > 1) {
      const prevIndex = this.shelves.findIndex(
        (item) => item.level === level - 1
      );
      if (prevIndex !== -1) {
        this.shelves[index].level -= 1;
        this.shelves[prevIndex].level += 1;
      }
    }
    this.shelves = [...this.shelves];
    this._renameShelves();
  }

  set rackData(rack: IClientRackModel | null) {
    this.rack = rack;
    this.shelves = rack?.shelves || [];
    this._renameShelves();
  }

  async editRackAPI(): Promise<boolean> {
    try {
      this._renameShelves();
      await updateItem(`${environment.serviceApi}ui/rack/${this.rack?.id}`, {
        name: this.rack?.name,
        description: this.rack?.description,
        length: this.rack?.length,
        depth: this.rack?.depth,
        type: this.rack?.type,
        mobility: this.rack?.mobility,
        zeroGround: this.rack?.zeroGround,
        numberingRack: this.rack?.numberingRack,
        rackStartsWith: this.rack?.rackStartsWith,
        numberingShelf: this.rack?.numberingShelf,
        shelfStartsWith: this.rack?.shelfStartsWith,
        shelves: this.shelves.map((shelf) => {
          return {
            name: shelf.name,
            rackId: shelf.rackId,
            level: shelf.level,
            purpose: shelf.purpose,
            positions: shelf.positions,
            height: shelf.height,
          };
        }),
      });
      return true;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error(errorModel.message);
      return false;
    }
  }

  get isDrawerOpen() {
    return this.drawer;
  }
  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLeader() {
    return this.currentUserStore.isLeaderRole || false;
  }
  get isUser() {
    return this.currentUserStore.isUserRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded;
  }

  get rightRemoveShelv() {
    return this.isAdmin || this.isLeader;
  }
  get rightEditShelv() {
    return this.isAdmin || this.isLeader;
  }
  get rightMoveShelv() {
    return this.isAdmin || this.isLeader;
  }
}
