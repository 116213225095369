import { environment } from "environment";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { IItemsModel } from "models/server-models";
import { IItemsFilterParams } from "../utils/filter-utils";

export class ItemsDataTableProvider extends DataTableStore<
  IItemsModel,
  IItemsFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();

  get endpointUrl(): string {
    return `ui/${this.stationId}/lot/${this.orderId}`;
  }

  protected serviceApiUrl = environment.serviceApi;
  get stationId() {
    return this.sharedContextStore.appContext.currentStationId;
  }

  orderId: number | null = null;

  setOrderId(id: number) {
    this.orderId = id;
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setDate(timeFrom.getDate() - 1);
    return timeFrom;
  }

  get defaultFilter() {
    return new Map<IItemsFilterParams, any>([]);
  }

  get filterDependencies(): Map<IItemsFilterParams, IItemsFilterParams[]> {
    return new Map<IItemsFilterParams, IItemsFilterParams[]>();
  }
}
