import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { Logout } from "@mui/icons-material";
import { IAPIClientTerminalModel } from "models/client-models";
import Button from "wes_shell_app/button";
import useConfirmDialog from "wes_shell_app/use-confirm-dialog";
import ConfirmDialog from "wes_shell_app/confirm-dialog";
import { TerminalViewStore } from "../stores/view/terminal-view-store";
import { useTerminalStore } from "../hooks/use-terminal-store";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "firstname",
    sortKey: "firstname",
  },
  {
    label: "surname",
    sortKey: "surname",
  },
  {
    label: "cardId",
    sortKey: "cardId",
  },
  {
    label: "process",
    sortKey: "process",
  },
  {
    label: "terminalId",
    sortKey: "terminalId",
  },
  {
    label: "ipAddress",
  },
  {
    label: "registered",
    sortKey: "registered",
  },
  {
    label: "unregistered",
  },
  {
    label: "",
  },
];

export const TerminalDataTable = observer(() => {
  const store = useTerminalStore();

  if (!store.isLoaded) return null;
  const confirmDialog = useConfirmDialog();
  return (
    <>
      <ConfirmDialog onConfirm={() => {}} />
      <DataTable
        page={store.page}
        isLoading={!store.isLoaded}
        onPageChange={store.setPage}
        activeSortProperty={store.sortBy}
        onSortChange={store.setSort}
        onDirectionChange={store.setDirection}
        direction={store.direction}
        pageCount={store.totalCount}
        rows={store.items}
        pageSize={store.pageSize}
        onPageSizeChange={store.setPageSize}
        columns={columns}
        rowTemplate={(item) => (
          <RowTemplate
            key={item.id}
            item={item}
            store={store}
            confirmDialog={confirmDialog}
          />
        )}
      />
      <ConfirmDialog
        onConfirm={() => {
          store.logOut(confirmDialog.item);
        }}
      />
    </>
  );
});

export type IRowTemplateProps = {
  item: IAPIClientTerminalModel;
  store: TerminalViewStore;
  confirmDialog: { open: (item) => void };
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const {
    firstname,
    surname,
    cardId,
    process,
    terminalId,
    ipAddress,
    registered,
    unregistered,
  } = props.item;

  return (
    <>
      <TableRow>
        <TableCell>{firstname}</TableCell>
        <TableCell>{surname}</TableCell>
        <TableCell>{cardId}</TableCell>
        <TableCell>{process}</TableCell>
        <TableCell>{terminalId}</TableCell>
        <TableCell>{ipAddress}</TableCell>
        <TableCell>{registered}</TableCell>
        <TableCell>{unregistered}</TableCell>
        {props.store.isAdmin && props.store.getFilterValue("isLoggedIn") && (
          <TableCell>
            <Button
              variant="icon"
              icon={<Logout color="warning" />}
              onClick={() => props.confirmDialog.open(props.item)}
            />
          </TableCell>
        )}
      </TableRow>
    </>
  );
});
