import ControlNumberbaseTableViewStore from "wes_shell_app/control-number-base-table-view-store";

import { ControlNumberDataTableProvider } from "./data/control-number-data-table-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { ControlNumberAisleDataProvider } from "./data/control-number-aisle-data-provider";
import { makeObservable } from "mobx";
import { ControlNumberReqProvider } from "./data/control-number-req-provider";
import {
  TControlNumberAisle,
  TControlNumberRackPositions,
} from "wes_shell_app/server-models";
import { TControlNumberFilterParams } from "wes_shell_app/control-number-utils";

export class ControlNumberViewStore extends ControlNumberbaseTableViewStore<
  TControlNumberRackPositions,
  TControlNumberFilterParams,
  ControlNumberDataTableProvider,
  TControlNumberAisle,
  ControlNumberAisleDataProvider,
  ControlNumberReqProvider
> {
  constructor() {
    super(
      new ControlNumberDataTableProvider(),
      new ControlNumberAisleDataProvider(),
      new ControlNumberReqProvider()
    );
    makeObservable(this, {});
  }

  private readonly appContext = new SharedContextStore();

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }

  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
}
