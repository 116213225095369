/**
 * Models of all data comming from server via API.
 * Type declarations according to Swagger.
 */

import { IWithId } from "wes_shell_app/api-types";
import { Severity } from "wes_shell_app/types";

/* WSS started */
export enum WebsocketMessageType {
  headerInfo = "headerInfo",
  process = "process",
  technical = "technical",
  workersWithTask = "workersWithTask",
  readyOrders = "readyOrders",
}

export type IWebsocketMessageModel =
  | IWebsocketModelProcessMessage
  | IWebsocketModelTechnicalMessage
  | IWebsocketModelHeaderInfoMessage
  | IWebsocketModelWorkersWithTaskMessage
  | IWebsocketModelReadyOrdersMessage;

export type IWebSocketHeaderInfoModel = {
  id: number;
  currentWorkers: number;
  ordersTotalToday: number;
  ordersCompletedToday: number;
  picksTotalToday: number;
  picksCompletedToday: number;
};

export type IWebsocketTableMessagesServerModelContent = {
  id: number;
  issued: Date;
  severity: Severity;
  confirmed: boolean;
  message: string;
  appmoduleUuid: string;
};

export type IWebsocketTableMessagesModel = {
  content: IWebsocketTableMessagesServerModelContent[];
};

export type IWebsocketModelProcessMessage = {
  message: IWebsocketTableMessagesModel;
  messageType: WebsocketMessageType.process;
};

export type IWebsocketModelTechnicalMessage = {
  message: IWebsocketTableMessagesModel;
  messageType: WebsocketMessageType.technical;
};

export type IWebsocketModelHeaderInfoMessage = {
  message: IWebSocketHeaderInfoModel;
  messageType: WebsocketMessageType.headerInfo;
};

export type IWebsocketModelWorkersWithTaskMessage = {
  message: IWebsocketTableWorkersWithTaskMessagesModel;
  messageType: WebsocketMessageType.workersWithTask;
};

export type IWebsocketTableWorkersWithTaskMessagesModel = {
  content: IWebsocketTableWorkersWithTaskServerModelContent[];
};

export type IWebsocketTableWorkersWithTaskServerModelContent = {
  id: number;
  firstName: string;
  lastName: string;
  orderNumber: number;
  fulfilment: number;
  terminalId: string;
  cardId: number;
  login: boolean;
};

export type IWebsocketModelReadyOrdersMessage = {
  message: IWebsocketTableReadyOrdersMessagesModel;
  messageType: WebsocketMessageType.readyOrders;
};

export type IWebsocketTableReadyOrdersMessagesModel = {
  content: IWebsocketTableReadyOrdersServerModelContent[];
};

export type IWebsocketTableReadyOrdersServerModelContent = {
  id: number;
  priority: number;
  orderNumber: number;
  issued: Date;
  preparedTo: Date;
};

export enum IAPIPrinterType {
  NETWORK = "network",
  PORTABLEBT = "portableBT",
}

export type IAPIPrinterModelsProtocols = {
  model: string[];
  protocol: string[];
};

export type IAPIPrinterModel = {
  id: number;
  printerCode: string;
  ipAddress?: string;
  type: IAPIPrinterType;
  name: string;
  description?: string;
  stationId: number;
  protocol: string;
  created: Date;
  updated: Date;
  model: string;
  btName: string | null;
};

export type IRackModel = {
  active?: boolean;
  name: string;
  stationId?: number;
  description: string;
  length: number;
  height?: number;
  depth: number;
  type: string;
  mobility: boolean;
  zeroGround: boolean;
  numberingRack: boolean;
  rackStartsWith: string;
  numberingShelf: boolean;
  shelfStartsWith: string;
  shelves: IServerCreateRackShelfModel[];
};

export type IServerCreateRackModel = IWithId & IRackModel;

export type IServerCreateRackShelfModel = IWithId & {
  shelfId: number;
  name: string;
  purpose: string;
  rackId: number;
  level: number;
  positions: number;
  height: number;
};

export type IServerShelfPurposeModel = {
  content: string[];
};

export type IServerRackModel = IWithId & IRackModel;

export type IAPITerminalModel = {
  id: number;
  firstname: string;
  surname: string;
  cardId: number;
  process: string;
  terminalId: string;
  ipAddress: string;
  registered: Date;
  unregistered: Date;
};

export type IOrdersModel = {
  id: number;
  warehouseId: number;
  warehouseName: string;
  stationId: number;
  stationName: string;
  batchId: number;
  lot: number;
  workplaceId: number;
  workplaceName: string;
  zoneExternal: string;
  status: string;
  supportType: string;
  supportCode: string;
  supportCount: number;
  customer: string;
  expDockAddress: string;
  checkDigit: string;
  lotType: string;
  qtySum: number;
  lotOrder: number;
  itemsCount: number;
  issued: string;
  preparedTo: string;
  lotStartDate: string | null;
  lotEndDate: string | null;
  priority: number;
};

export type IItemsModel = {
  id: number;
  line: number;
  pickOrder: number;
  pickAddress: string;
  area: string;
  aisle: string;
  bay: string;
  level: string;
  cell: string;
  checkDigit: string;
  status: string;
  itemId: string;
  itemLogistics: string;
  itemName: string;
  weightFlag: string;
  avgWeight: number;
  minWeightLimit: number;
  maxWeightLimit: number;
  pickQtyPc: number;
  pickQtyPu: number;
  pickUnit: string;
  pickedQtyPc: number;
  availableQtyPc: number;
  pcInPu: number;
  pcInSubcart: number;
  subcartInCart: number;
  autoPickId: string;
  comment: string;
};
