import { environment } from "environment";
import DataTableStore from "wes_shell_app/data-table-store";
import { TWarehouseMapWorplaceDataTableModel } from "wes_shell_app/server-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { TWarehouseMapWorkplaceFilterParams } from "wes_shell_app/warehouse-map-utils";

export class WarehousMapDataWorkplaceTableProvider extends DataTableStore<
  TWarehouseMapWorplaceDataTableModel,
  TWarehouseMapWorkplaceFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();

  protected serviceApiUrl = environment.serviceApi;

  get url(): string {
    return `ui/map`;
  }

  get endpointUrl(): string {
    return `${this.url}/${this.sharedContextStore.appContext.currentStationId}/workplace`;
  }
}
