import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";
import { environment } from "environment";
import { TWarehouseMapRackModel } from "wes_shell_app/server-models";

export class WarehouseMapRowRequestProvider extends SingleDataStoreProvider<TWarehouseMapRackModel> {
  protected serviceApiUrl = environment.serviceApi;

  get endpointUrl(): string {
    return `${this.url}`;
  }

  get url(): string {
    return `ui/map/row`;
  }
}
