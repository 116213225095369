import { observer } from "mobx-react";
import { usePrintersStore } from "../hooks/use-printers-store";
import { Box } from "@mui/material";
import TextInputField from "wes_shell_app/text-input-field";
import { PrinterTypeSelector } from "./printer-type-selector";
import { PrinterAddDialogForm } from "./printer-add-dialog";
import { StoreRounded } from "@mui/icons-material";

export const PrintersDataTableFilter = observer(() => {
  const store = usePrintersStore();

  return (
    <>
      <Box className="flex flex-row justify-between space-x-2 w-full mt-2 mb-6">
        <Box className="w-2/5 flex gap-5">
          <TextInputField
            label="name"
            value={store.getFilterValue("name")}
            onChange={(v) => store.setFilter("name", v)}
          />
          <PrinterTypeSelector
            onChange={(v) => store.setFilter("type", v)}
            onClear={() => store.clearFilter("type")}
            value={store.getFilterValue("type")}
          />
        </Box>
        {store.isAdmin && <PrinterAddDialogForm />}
      </Box>
    </>
  );
});
