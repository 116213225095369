import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";
import { IAPIPrinterModel } from "models/server-models";
import { environment } from "environment";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class PrintSingleDataProvider extends SingleDataStoreProvider<IAPIPrinterModel> {
  get endpointUrl(): string {
    return `ui/${this.stationId}/printer`;
  }
  readonly sharedContextStore = new SharedContextStore();
  protected serviceApiUrl = environment.serviceApi;
  get stationId() {
    return this.sharedContextStore.appContext.currentStationId;
  }
}
