import WarehouseMapControlNumber from "wes_shell_app/warehouse-map-control-number";
import { useControlNumberStore } from "../hooks/use-control-number-store";
import BaseView from "wes_shell_app/base-view";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useControlNumberWorkPlaceStore } from "../hooks/use-control-number-workplace-store";

const ControlNumberViews = observer(() => {
  const store = useControlNumberStore();
  const workplaceStore = useControlNumberWorkPlaceStore();

  return (
    <BaseView>
      <WarehouseMapControlNumber
        controlNumberStore={store}
        workplaceStore={workplaceStore}
        workplaceIsAdmin={workplaceStore.isAdmin}
        controlNumberIsAdmin={store.isAdmin}
        activeSortProperty={store.sortBy}
        mapRow={store.getMapRow}
        aisleRacks={store.getAislesRacks}
        onDirectionChange={store.setDirection}
        onPageSizeChange={store.setPageSize}
        onSortChange={store.setSort}
        pageCount={store.totalCount}
        rows={store.items}
        onPageChange={store.setPage}
      />
    </BaseView>
  );
});

export default ControlNumberViews;
