import { environment } from "environment";
import ControlNumberAisleDataStore from "wes_shell_app/control-number-aisle-data-store";
import { TControlNumberAisle } from "wes_shell_app/server-models";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class ControlNumberAisleDataProvider extends ControlNumberAisleDataStore<TControlNumberAisle> {
  protected serviceApiUrl = environment.serviceApi;
  readonly sharedContextStore = new SharedContextStore();

  get url(): string {
    return `ui/map`;
  }

  protected workplaceId: number;

  setWorkplaceId(value: number) {
    this.workplaceId = value;
  }

  get endpointUrl(): string {
    return `${this.url}/${this.workplaceId}/aisle`;
  }
}
